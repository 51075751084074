import { FC } from 'react'
import { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'
import styles from './HeroPostRow.module.css'
import clsx from 'clsx'
import { AppEventsContext } from '../contexts/AppEventsContext'
import Linked from '../service/Linked'
import AppImage from '../ui/AppImage'

interface HeroPostRowProps {
  post: DSPostShortWithAuthor
}

const HeroPostRow: FC<HeroPostRowProps & BasicStyledComponent> = ({ post, className }) => {
  const { appEvent } = AppEventsContext.useContainer()
  return (
    <Linked
      className={clsx(styles.container, className)}
      onClick={() => {
        appEvent({
          event: 'widget-interaction',
          widgetType: 'Post Widget',
          label: post.title,
          url: post.pLink.href,
        })
      }}
      {...post.pLink}
    >
      <div className={styles.content}>
        <div className={styles.title}>{post.title}</div>
        <div className={styles.bottomLine}>
          {post.author && <div className={styles.author}>{post.author.name}</div>}
          {post.category && <div className={styles.category}>{post.category}</div>}
        </div>
      </div>
      {post.image ? (
        <div className={styles.imageContainer}>
          <AppImage
            className={styles.image}
            layout="fill"
            sizes={[{ bp: 'lg', val: 'rem(144)' }, 'rem(112)']}
            image={post.image}
          />
        </div>
      ) : null}
    </Linked>
  )
}

export default HeroPostRow
