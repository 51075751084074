import { FC } from 'react'
import styles from './HeroPostRowCounted.module.css'
import { DSPostShortWithAuthor } from 'shared-definitions/types'
import HeroPostRow from './HeroPostRow'

interface HeroPostRowCountedProps {
  count: number
  post: DSPostShortWithAuthor
}

const HeroPostRowCounted: FC<HeroPostRowCountedProps> = ({ post, count }) => (
  <div className={styles.container}>
    <div className={styles.number}>{count}</div>
    <HeroPostRow post={post} />
  </div>
)

export default HeroPostRowCounted
