import clsx from 'clsx'
import { FC } from 'react'
import { BasicStyledComponent, DSTeamMemberShort } from 'shared-definitions/types'
import styles from './AuthorShortLine.module.css'
import AppImage from './AppImage'

interface AuthorShortLineProps {
  author: DSTeamMemberShort
}

const AuthorShortLine: FC<AuthorShortLineProps & BasicStyledComponent> = ({
  author,
  className,
}) => (
  <div className={clsx(styles.container, className)}>
    {author.image && (
      <AppImage sizes={[24]} className={styles.image} layout="raw" image={author.image} />
    )}
    {author.name}
  </div>
)

export default AuthorShortLine
