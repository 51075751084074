import { FC } from 'react'
import { DSCBHero } from 'shared-definitions/types'
import HomeHero from 'shared-components/ui/HomeHero'
import styles from './CBHero.module.css'

interface CHHeroProps {
  data: DSCBHero
}

const CBHero: FC<CHHeroProps> = ({ data }) => <HomeHero {...data} className={styles.container} />

export default CBHero
