import { FC } from 'react'
import { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'
import styles from './HeroAltPostCard.module.css'
import clsx from 'clsx'
import { AppEventsContext } from '../contexts/AppEventsContext'
import Linked from '../service/Linked'
import AppImage from '../ui/AppImage'
import AuthorShortLine from '../ui/AuthorShortLine'
import PostCardUI from './PostCardUI'

interface HeroAltPostCardProps {
  post: DSPostShortWithAuthor
}

const HeroAltPostCard: FC<HeroAltPostCardProps & BasicStyledComponent> = ({ className, post }) => {
  const { appEvent } = AppEventsContext.useContainer()
  return (
    <Linked
      className={clsx(styles.container, className)}
      onClick={() => {
        appEvent({
          event: 'widget-interaction',
          widgetType: 'Post Widget',
          label: post.title,
          url: post.pLink.href,
        })
      }}
      {...post.pLink}
    >
      {post.image ? (
        <div className={styles.imageContainer}>
          <AppImage
            className={styles.image}
            layout="fill"
            sizes={[{ bp: 'lg', val: 400 }, 'calc(100vw - rem(48))']}
            image={post.image}
          />
          <PostCardUI post={post} />
        </div>
      ) : null}
      <div className={styles.content}>
        {post.category && <div className={styles.category}>{post.category}</div>}
        <div className={styles.title}>{post.title}</div>
        {post.author && <AuthorShortLine className={styles.author} author={post.author} />}
      </div>
    </Linked>
  )
}

export default HeroAltPostCard
