import clsx from 'clsx'
import { FC } from 'react'
import { BasicStyledComponent, DSCBHero } from 'shared-definitions/types'
import HeroAltPostCard from '../posts/HeroAltPostCard'
import HeroFocusPostCard from '../posts/HeroFocusPostCard'
import HeroPostRowCounted from '../posts/HeroPostRowCounted'
import styles from './HomeHero.module.css'

const HomeHero: FC<DSCBHero & BasicStyledComponent> = ({
  className,
  focusedCard,
  altCards,
  listing,
}) => (
  <div className={clsx(styles.container, className)}>
    <HeroFocusPostCard className={styles.top} post={focusedCard} />
    <div className={styles.bottom}>
      {altCards.map((card, index) => (
        <HeroAltPostCard key={index} className={styles.card} post={card} />
      ))}
    </div>
    <div className={styles.right}>
      <h5 className={styles.rightLabel}>{listing.title}</h5>
      {listing.items.map((item, index) => (
        <>
          {index > 0 && <hr className={styles.rowSeparator} />}
          <HeroPostRowCounted count={index + 1} key={index} post={item} />
        </>
      ))}
    </div>
  </div>
)

export default HomeHero
